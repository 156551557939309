.single-banner {
  padding-top: 150px;
  padding-bottom: 80px;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 900px;
  margin: 0 auto;
  .game-tags {
    margin-top: 5px;
    color: #fff;
  }
  .game-tags .ant-tag-default {
    background-color: #1a1a42;
  }
  h1 {
    font-size: 3rem;
    color: #fff;
    margin: 0;
  }
  h2 {
    font-size: 1.5rem;
    color: #fff;
    font-weight: 400;
  }
}

.blog-content {
  display: flex;
  gap: 30px;
  align-items: flex-start;
  .content-inr {
    width: 64.1%;
    flex: 0 0 64.1%;
    text-align: left;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: #fff;
    }
    .ant-typography {
      line-height: 1.8;
      color: #fff !important;
    }
  }
  .sider {
    width: 33.5%;
    flex: 0 0 33.5%;
    .detail-info {
      padding: 20px;
      background-color: #171734;
      border-radius: 20px;
      box-shadow: 0px 20px 80px rgba(85, 114, 246, 0.25);
      text-align: left;
      width: 100%;
      margin-bottom: 30px;
      h3 {
        margin: 0 0 20px;
        color: #fff;
      }
      .price {
        width: 100%;
        justify-content: space-between;
        align-items: flex-end;
        .text.ant-typography {
          color: #fff;
          font-size: 0.75rem;
        }
        h5 {
          font-size: 0.875rem;
          margin: 0;
          color: #fff;
        }
        .price-text {
          font-size: 2rem;
          line-height: 1;
          color: #ffb100;
        }
      }
      .instant-tag {
        margin-top: 15px !important;
      }
      .seat-tag {
        padding: 6px 15px;
        width: min-content;
      }
    }
  }
  .post-thumbnail {
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 1.5rem;
    line-height: 12px;
    box-shadow: 0px 20px 80px rgba(85, 114, 246, 0.25);
    .ant-image {
      width: 100%;
      &::after {
        content: "";
        display: block;
        padding-top: 50%;
      }
    }
    .ant-image-img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.capign-heading {
  margin: 20px 0 15px;
  font-size: 1rem !important;
  color: #fff !important;
  margin-bottom: 3px !important;
}

.session-listing {
  font-size: 0.875rem;
  padding: 0 20px;
  color: #fff;
  line-height: 1.8rem;
}

.timezone-text {
  margin: 0;
  font-size: 0.75rem;
  font-weight: 400;
  color: #b1b1c8;
  text-align: left;
}

.gm-card {
  .card-header {
    align-items: center;
    margin-bottom: 15px;
  }
  h2 {
    font-size: 1.2rem;
    color: #fff;
    margin: 0 0 5px;
    span {
      font-size: 1rem;
      color: #b1b1c8;
    }
  }
  .footer {
    margin-top: 20px;
    width: 100%;
    justify-content: center;
  }
  .text {
    font-size: 0.875rem;
    color: #b1b1c88c !important;
  }
}

// .review-card {
//   border:solid 1px ;
// }

.game-master-list {
  padding-left: 30px;
  li {
    font-size: 0.75rem;
    color: #fff;
    &.game-tags {
      @media screen and (max-width: 991px) {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        align-items: center;
      }
    }
    span {
      font-weight: 400;
      color: #b1b1c8;
      .tag {
        font-size: 12px;
        display: block;
      }
    }
    & + li {
      margin-top: 5px;
    }
  }
}
