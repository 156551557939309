.blogpost-wrap {
  .page-header {
    min-height: 150px;
    margin-bottom: 30px;
  }
  .sider {
    padding: 20px;
    background-color: #171734;
    border-radius: 20px;
    box-shadow: 0px 20px 80px rgba(85, 114, 246, 0.25);
    text-align: left;
    width: 100%;
    max-width: 25%;
    margin-bottom: 30px;
    h3 {
      margin-top: 0;
      color: #fff;
      margin-bottom: 15px;
    }
  }
}

.blog-content-wrap {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  padding-top: 80px;
  .ant-pagination {
    width: 100%;
  }
  .blog-card {
    max-width: calc(25% - 12px);
    background-color: #171734;
    border-radius: 15px;
    text-align: left;
    overflow: hidden;
    width: 100%;
    .ant-image {
      width: 100%;
      height: 200px;
      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 100%;
        object-fit: cover;
      }
    }
    h4 {
      font-size: 1.25rem;
      color: #fff;
      margin: 0 0 10px;
    }
    .description {
      font-size: 0.875rem;
      color: #b1b1c8;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .blog-content-inr {
      padding: 20px;
      .game-tags {
        font-size: 0.75rem;
        color: #fff;
        margin-bottom: 12px;
      }
      .game-tags .ant-tag-default {
        background-color: #34344e;
        color: #fff;
        border: none;
        margin: 0;
        min-height: 26px;
        border-radius: 15px;
        display: flex;
        padding: 0 10px;
        align-items: center;
      }
      a {
        color: #ffb100;
      }
    }
  }
}

.date-create {
  color: #b1b1c8;
  font-size: 0.75rem;
  display: block;
  margin-bottom: 10px;
  span {
    color: #fff;
  }
}

.latest-post-wrap {
  & + .latest-post-wrap {
    margin-top: 15px;
  }
  img {
    border: 4px;
  }
  h5 {
    margin: 0;
    color: #fff;
  }
  .description {
    color: #b1b1c8;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 150px;
    margin: 0;
  }
}
