.forms-wrap {
  padding-top: 50px;
  padding-bottom: 50px;
}

.startgames-forms {
  input,
  textarea,
  .ant-select-selector {
    border-radius: 4px !important;
    text-align: left;
  }
  label {
    font-size: 1rem !important;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.88);
  }
  .ant-picker {
    width: 100%;
    border-radius: 4px;
  }
  .submit-btn {
    float: right;
    border-radius: 4px;
  }
  .ant-form-item-control-input-content {
    text-align: left;
  }
  .ant-input-affix-wrapper {
    border-radius: 4px !important;
  }
}

.forms-wrapper {
  max-width: 800px;
  @media screen and (min-width: 767px) {
    padding: 24px;
  }
  margin: 0 auto;
  border-radius: 6px;
  .ql-container.ql-snow,
  .ql-toolbar.ql-snow {
    background-color: #171734 !important;
    border: solid 1px rgba(121, 121, 121, 0.2588235294) !important;
    .ql-fill {
      fill: #b1b1c8;
    }
    .ql-picker {
      color: #b1b1c8;
    }
  }
  .ql-stroke {
    stroke: #b1b1c8;
  }
  .ql-editor {
    min-height: 150px;
    color: #b1b1c8;
  }
  .ant-form-item-explain-error {
    text-align: left;
  }
  label {
    font-size: 14px !important;
    color: #fff !important;
    font-weight: 500;
    flex-direction: row-reverse;
    &:before {
      margin-left: 5px;
    }
    &:after {
      margin: 0 !important;
    }
  }
  .ant-input,
  textarea,
  .ant-select-selector,
  .ant-picker,
  .ant-picker-input input,
  .ant-input-affix-wrapper {
    color: #b1b1c8;
    border-color: #79797942 !important;
    &::placeholder,
    .ant-select-selection-placeholder,
    .ant-picker-input input::placeholder,
    .ant-picker-suffix,
    .ant-input-password-icon,
    .ant-input-password-icon:hover {
      color: #b1b1c871;
      font-size: 14px;
    }
  }
  .ant-input-number {
    border: none !important;
  }
  .ant-input-number-input-wrap input,
  .ant-select-selection-item {
    color: #b1b1c8 !important;
    &::placeholder {
      color: #b1b1c871;
    }
  }
  .ant-select-arrow {
    color: #b1b1c871;
  }
  input,
  textarea,
  .ant-select-selector,
  .ant-picker,
  .ant-input-affix-wrapper {
    background-color: #171734 !important;
  }
  .ant-select-selection-search input {
    color: #b1b1c8;
  }
}

.remember-field {
  .ant-form-item-control-input-content {
    text-align: left;
  }
}

.counter-field {
  width: 100%;
}

.profile-avatar {
  position: relative;
  .ant-image {
    width: 160px;
    height: 160px;
  }
  img.user-avatar {
    position: absolute;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
  }
  button {
    position: absolute;
    bottom: 5px;
    right: 0;
    background-color: #d9d9d9;
    box-shadow: 0px 4px 4px 0px #00000040;
  }
}

.upload-btn {
  width: 40px !important;
  height: 40px !important;
  padding: 0 !important;
}
