.banner {
  display: flex;
  align-items: center;
  background-image: url(../../images/banner.jpg);
  min-height: 100vh;
  background-size: cover;
  text-align: left;
  padding-top: 70px;
  position: relative;

  h1 {
    @media screen and (min-width: 1800px) {
      max-width: 100%;
    }

    @media screen and (min-width: 768px) {
      font-size: 4rem;
    }

    @media screen and (max-width: 767px) {
      font-size: 2rem;
    }

    line-height: 1.1;
    color: #fff;
    font-family: "Black Ops One";
    max-width: 800px;
    position: relative;
    margin: 0;
    margin-bottom: 30px !important;
  }

  .banner-text {
    position: relative;
    font-size: 1.25rem;
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 30px;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background-color: #021d3b;
    opacity: 0.4;
    width: 100%;
    height: 100%;
  }
}

.banner-btn-wrap {
  @media screen and (max-width: 575px) {
    .ant-space-item {
      width: calc(50% - 4px);
    }

    button {
      width: 100%;
    }
  }
}

.payouts-wrap {
  padding: 80px 15px;
  padding-top: 100px !important;
  max-width: 750px;
  width: 100%;
  margin: 0 auto;

  h3,
  h4 {
    text-align: left;
    color: #fff;
  }

  h4 {
    font-weight: 500;
  }

  .section-paragraph {
    font-size: 1.125rem;
    color: #fff;
    margin-bottom: 30px;
    text-align: left;

    a {
      color: #ffb100;
    }
  }

  .ant-form {
    margin-top: 50px;
  }
}

/* UPCOMING GAMES SECTION */

.upcoming-games {
  @media screen and (min-width: 768px) {
    padding: 0 50px 100px;
  }

  @media screen and (max-width: 767px) {
    padding: 0 0 50px;
  }
}

.upcoming-carousel {
  .slick-arrow {
    position: absolute;
    right: 10px;
    top: -40px;

    &:before {
      font-size: 2rem;
    }

    &.slick-prev {
      left: auto;
      right: 45px;
    }
  }

  .slick-track {
    display: flex;
    gap: 5px;
  }
}

.upcoming-cards {
  position: relative;

  @media screen and (min-width: 801px) {
    max-width: 19.6875rem;
  }

  width: 100%;
  background-color: #14182f;
  border-radius: 1.25rem;
  padding: 0.625rem;
  margin: 0 0.3125rem;

  .card-content {
    padding: 0.125rem 0.3125rem 0;
    text-align: left;

    .game-master-list {
      list-style: none;
      padding-left: 0;

      .game-tags {
        .ant-tag-default {
          font-size: 0.75rem;
          display: inline-block;
          padding: 0.3125rem 0.94rem;

          @media screen and (min-width: 992px) {
            margin-left: 0.625rem;
          }

          @media screen and (max-width: 991px) {
            margin-top: 0.3125rem;
            font-size: 0.625rem;
          }
        }
      }
    }
  }

  .card-heading {
    text-align: left;
    color: #fff;
    font-size: 0.875rem;
    margin: 8px 0;
    font-weight: 400;
    font-family: var(--font-family);
  }

  &>.ant-image {
    height: 180px;
    width: 100%;
    overflow: hidden;
    position: relative;
    border-radius: 20px;

    &::after {
      content: "";
      display: block;
      padding-top: 100%;
    }

    img.ant-image-img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
    }
  }

  .coming-date {
    font-size: 0.75rem;
    color: #ffb100;
    margin: 0.375rem 0 0.625rem;
  }

  .footer {
    width: 100%;
    margin-top: 0.94rem;

    @media screen and (min-width: 992px) {
      justify-content: center;
    }

    @media screen and (max-width: 991px) {
      flex-wrap: wrap;
    }
  }
}

.price-tag {
  margin-right: 0;
  border-radius: 0.75rem;
  font-size: 0.875rem;
  font-weight: 700;
  min-height: 30px;
  padding: 0.31rem 0.5rem;
  text-align: center;
}

.our-benefit {
  padding-bottom: 80px;
}

.benefit-box {
  background-color: #171734;
  padding: 3.125rem 1.875rem;
  border-radius: 1.875rem;
  text-align: left;
  height: 100%;

  .anticon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.25rem;
    border-radius: 0.625rem;
    color: #fff;
    margin-bottom: 1.56rem;
    background-color: #ffb100;
  }

  h4 {
    font-size: 1.5rem;
    color: #fff;
    font-weight: bold;
    font-family: var(--font-family);
    margin-top: 0;
    margin-bottom: 1.56rem;
  }

  .text {
    font-size: 0.875rem;
    color: #b1b1c8;
    margin-bottom: 0;
  }
}

.team-wrap {
  padding-bottom: 7rem;
}

.team-card {
  padding: 2rem 1.5rem;
  width: 100%;
  border-radius: 1.25rem;
  background-color: #171734;
  position: relative;
  box-shadow: 0px 20px 80px rgb(85 114 246 / 25%);
  display: flex;
  cursor: pointer;
  transition: all ease-in-out 0.3s;

  &:hover {
    transform: scale(1.03);
  }

  .ant-image {
    border-radius: 6.25rem;
    overflow: hidden;

    img {
      object-fit: cover;
    }
  }

  .card-heading {
    font-size: 1.25rem;
    color: #fff;
    margin-top: 0.625rem;
  }

  .team-list {
    list-style: none;
    padding-left: 0;
    text-align: left;

    li {
      font-size: 0.875rem;
      color: #92a4b3;

      &+li {
        margin-top: 15px;
      }

      .price {
        font-size: 0.94rem;
      }

      @media screen and (max-width: 575px) {
        text-align: center;
      }
    }
  }

  .average-rating {
    font-size: 0.75em;
    color: #000;
    padding: 0.1875rem 0.3125rem;
    background-color: #fff;
    border-radius: 0.3125rem;
  }

  .text {
    font-size: 0.75rem;
    color: rgb(85, 114, 246);
  }

  @media screen and (min-width: 576px) {
    gap: 1.25rem;
  }

  @media screen and (max-width: 575px) {
    flex-direction: column;
  }
}

.blog-post-wrap {
  @media screen and (min-width: 768px) {
    padding-bottom: 6.25rem;
  }

  @media screen and (max-width: 767px) {
    padding-bottom: 3.75rem;
  }
}

.see-all-btn {
  font-size: 1rem !important;
  height: 2.5rem !important;
  line-height: 1.25rem;
  border-radius: 3.125rem !important;
  margin-top: 3.125rem;
  min-width: 10.625rem;
}

.blog-post-card-wrap {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;

  .blog-post-card {
    display: flex;
    cursor: pointer;

    @media screen and (min-width: 768px) {
      width: 31.6%;
    }

    @media screen and (max-width: 767px) and (min-width: 576px) {
      width: 50%;
    }

    @media screen and (max-width: 575px) {
      width: 100%;
    }

    align-items: flex-end;
    position: relative;
    border-radius: 1.875rem;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      background: #151b2a;
      background: linear-gradient(to bottom,
          #151b2a33 20%,
          #151b2af0 100%,
          #151b2a 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#151b2a", endColorstr="#151b2a", GradientType=0);
      z-index: 1;
      top: 0;
      bottom: 0;
    }

    .ant-image {
      min-height: 300px;
      position: relative;
      width: 100%;

      &:after {
        content: "";
        display: block;
        padding-top: 100%;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .content {
      position: absolute;
      bottom: 20px;
      left: 20px;
      text-align: left;
      z-index: 1;

      h5 {
        font-size: 1.25rem;
        color: #fff;
        margin: 0 0 10px;
      }
    }
  }
}

.become-wrap {
  position: relative;

  @media screen and (min-width: 768px) {
    padding: 6.25rem;
  }

  @media screen and (max-width: 767px) {
    padding: 3.125rem 0.94rem;
  }

  background-image: url(../../images/walking_book.webp);
  background-position: center;
  background-size: cover;
  border-radius: 1.56rem;
  overflow: hidden;

  h2 {
    margin-bottom: 0.94rem !important;
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background-color: #021d3b;
    opacity: 0.6;
    width: 100%;
    height: 100%;
  }

  .become-inr {
    position: relative;
    z-index: 1;

    .text {
      font-size: 1.125rem;
      color: rgba(255, 255, 255, 0.9);
    }

    a {
      display: flex;
      margin: 0 auto;
      align-items: center;
      justify-content: center;
      color: #fff;
      border-radius: 0.875rem;
      max-width: 8.75rem;
      height: 2.5rem;
      background-color: #ffb100;
    }
  }
}

.game-tags {
  .instant-tag {
    margin: 0.3125rem 0 0;
    padding: 0.25rem 0.625rem;
    border-radius: 0.875rem;
  }

  .ant-tag-default {
    background-color: #3e4256;
    color: #fff;
    border: none;
    margin: 0;
    min-height: 30px;
    border-radius: 0.94rem;
    display: flex;
    padding: 0 0.625rem;
    align-items: center;
  }
}

.seat-tag {
  display: flex;
  align-items: center;
  gap: 5px;
  text-align: left;

  .filled-text {
    font-size: 0.69rem;
    line-height: 0.8125rem;
    font-weight: 400;

    span {
      display: block;
      font-size: 0.625rem;
      color: #7b858e;
    }
  }

  .ant-image {
    background-color: #92d23f;
    min-height: 25px;
    min-width: 25px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    &-img {
      width: auto;
    }
  }
}

.steps-wrap {
  padding-top: 100px;
  padding-bottom: 100px;

  .step-box {
    @media screen and (min-width: 1920px) {
      max-width: 1100px;
    }

    max-width: 900px;
    margin: 0 auto !important;
  }
}

.stripe-wrap {
  padding-top: 150px;
  display: flex;
  max-width: 900px;
  margin: 0 auto;

  .container {
    max-width: 875px;
    width: 100%;
  }

  .price-detail-card {
    margin-bottom: 20px;
    display: flex;
    gap: 15px;
    justify-content: center;
  }

  .price-detail {
    max-width: 500px;
    width: 100%;
    background-color: #171734;
    border: solid 1px rgba(38, 39, 46, 0.5411764706);
    border-radius: 10px;
    padding: 30px;

    h3 {
      margin: 0;
      color: #fff;
      text-align: left;
    }

    ul {
      padding-left: 0;
      margin-bottom: 40px;

      li {
        list-style: none;
        text-align: left;
        color: #fff;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        font-size: 1rem;
      }
    }

    .session-text {
      font-size: 1rem;
      color: #fff;
      text-align: left;
    }
  }

  .refund-policy-box {
    background-color: #171734;
    border: solid 1px rgba(38, 39, 46, 0.5411764706);
    border-radius: 10px;
    padding: 30px;
    margin-bottom: 60px;

    h3 {
      margin: 0;
      color: #fff;
      text-align: left;
    }

    .campaign-text {
      font-size: 1.5rem;
    }

    ul {
      padding-left: 20px;

      li {
        text-align: left;
        color: #fff;
        margin-bottom: 10px;
        font-size: 1rem;
      }
    }
  }
}

.payment-tabs {
  h3 {
    text-align: left;
    color: #fff;
    margin: 0;
    margin-bottom: 25px;
  }

  .ant-tabs-tabpane {
    padding-top: 30px;
  }

  .ant-tabs-nav-list {
    width: 100%;

    .ant-tabs-tab {
      flex: 1;
      justify-content: center;
      background-color: #171734;
      color: #fff;
      margin: 0;

      &-active {
        background-color: #ffb100;

        .ant-tabs-tab-btn {
          color: #fff;
        }
      }
    }
  }
}

.paying-with-card {
  .ant-table-thead {
    display: none;
  }

  td {
    font-size: 1.2rem;
    text-align: center;
    color: #fff;
    background-color: #171734;
    border-bottom: solid 10px #0e1020 !important;
  }

  .ant-table-row.ant-table-row-selected>.ant-table-cell {
    background-color: #ffb100 !important;
  }

  tr:hover {
    td {
      background-color: #ffb100 !important;
    }
  }
}

.paying-btn-wrap {
  display: flex;
  justify-content: flex-end;
}