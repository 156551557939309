.main-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  backdrop-filter: blur(10px);
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  min-height: 4.375rem;
  z-index: 1;
  @media screen and (max-width: 575px) {
    padding-inline: 20px;
  }
}

.header-right {
  display: flex;
  @media screen and (min-width: 576px) {
    gap: 30px;
  }
  @media screen and (max-width: 575px) {
    gap: 5px;
  }
  align-items: center;
  a {
    font-size: 0.875rem;
    color: rgba(255, 255, 255, 0.8);
  }
  .ant-dropdown {
    min-width: 200px !important;
    top: 60px !important;
    text-align: left;
    .ant-dropdown-menu-item:hover {
      background-color: rgba(85, 114, 246, 9%);
      a {
        color: #ffb100;
      }
    }
    &-menu {
      background-color: #171734 !important;
      &-item {
        &-divider {
          background-color: rgba(56, 56, 56, 0.6);
        }
        .anticon {
          color: #ffb100;
          margin-right: 0.625rem;
          min-width: 0.625rem;
        }
        color: #b1b1c8 !important;
      }
    }
  }
  &.show {
    .links {
      left: 0;
    }
  }
  .menu-wrap {
    @media screen and (max-width: 575px) {
      gap: 0 !important;
    }
  }
}

.or-text {
  font-weight: 400 !important;
  margin: 0;
  color: #d9d9d9 !important;
}

.logo-wrap {
  cursor: pointer;
  h4 {
    font-size: 1.25rem;
    color: #fff;
    margin: 0;
  }
}

.link-text,
.link-text:hover {
  color: #ffb100;
  text-decoration: underline;
}

.links {
  @media screen and (max-width: 575px) {
    display: block;
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: #000;
    left: -100%;
    top: 0;
    transition: all ease-in-out 0.3s;
    z-index: 1000;
  }
}

.toggle-menu {
  color: #fff;
  @media screen and (min-width: 576px) {
    display: none;
  }
}
