.stripe-wrap {
  padding-top: 150px;
  display: flex;
  max-width: 900px;
  margin: 0 auto;
  .container {
    max-width: 875px;
    width: 100%;
  }
  .price-detail-card {
    margin-bottom: 20px;
    display: flex;
    gap: 15px;
    justify-content: center;
  }
  .price-detail {
    max-width: 500px;
    width: 100%;
    background-color: #171734;
    border: solid 1px rgba(38, 39, 46, 0.5411764706);
    border-radius: 10px;
    padding: 30px;
    h3 {
      margin: 0;
      color: #fff;
      text-align: left;
    }
    ul {
      padding-left: 0;
      margin-bottom: 40px;
      li {
        list-style: none;
        text-align: left;
        color: #fff;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        font-size: 1rem;
      }
    }
    .session-text {
      font-size: 1rem;
      color: #fff;
      text-align: left;
    }
  }
  .refund-policy-box {
    background-color: #171734;
    border: solid 1px rgba(38, 39, 46, 0.5411764706);
    border-radius: 10px;
    padding: 30px;
    h3 {
      margin: 0;
      color: #fff;
      text-align: left;
    }
    .campaign-text {
      font-size: 1.5rem;
    }
    ul {
      padding-left: 20px;
      li {
        text-align: left;
        color: #fff;
        margin-bottom: 10px;
        font-size: 1rem;
      }
    }
  }
}
