.default-footer {
  @media screen and (min-width: 576px) {
    padding: 70px 0;
  }
  @media screen and (max-width: 575px) {
    padding: 50px 0;
  }
  background-image: url(../../images/footer-bg.png);
  background-size: cover;
  .seprator {
    border-top: solid 1px #26272e8a;
    margin-bottom: 60px;
  }
  h5 {
    font-size: 1.25rem;
    margin-top: 0;
    color: #fff;
    text-align: left;
  }
  ul {
    padding-left: 0;
    list-style: none;
    text-align: left;
    li {
      & + li {
        margin-top: 10px;
      }
      a {
        font-size: 0.875rem;
        color: #ffffff82;
      }
    }
  }
  .follow-us {
    margin-top: 30px;
    margin-bottom: 20px;
  }
}
