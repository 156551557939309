.nav-menu {
  justify-content: center;
  border-bottom: none;
  background-color: transparent;
  li:not(.ant-menu-item-selected),
  li:not(.ant-menu-item-selected):hover {
    color: #fff !important;
  }
}
.profile-settings {
  padding-top: 90px;
}
.page-header {
  position: relative;
  display: flex;
  text-align: left;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding: 10px 20px;
  border-radius: 6px;
  overflow: hidden;
  @media screen and (max-width: 767px) {
    flex-direction: column-reverse;
    justify-content: center;
  }
  .cover-upload-btn {
    position: absolute;
    z-index: 1;
    top: 2px;
    left: 5px;
    button {
      border-radius: 100px;
    }
  }
  .profile-thumbnail {
    width: 150px;
    height: 150px;
    border-radius: 100px;
    overflow: hidden;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .profile-avatar,
  .profile-thumbnail {
    position: relative;
    z-index: 1;
  }
  .profile-avatar {
    .ant-upload-list-item-container {
      border-radius: 100px;
      overflow: hidden;
    }
    .ant-upload-list-item {
      padding: 0 !important;
      border: none !important;
    }
  }
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(255, 177, 0, 0.7);
    z-index: 1;
  }
  .setting-cover {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .setting-content {
    position: relative;
    z-index: 1;
    @media screen and (max-width: 767px) {
      text-align: center;
    }
  }
  h2 {
    font-size: 1.8rem;
    margin: 0 0 5px;
    color: #1e000e;
  }
  .profile-text {
    font-size: 1.125rem;
    color: #1a1a1a;
  }
}

.setting-con {
  max-width: 900px !important;
  padding-bottom: 30px;
  @media screen and (min-width: 768px) {
    padding-top: 70px;
  }
  @media screen and (max-width: 767px) {
    padding-top: 50px;
  }
  h3 {
    margin-top: 15px;
  }
}

.setting-cards {
  border: none;
  cursor: pointer;
  @media screen and (min-width: 576px) {
    padding-top: 60px;
    padding-bottom: 60px;
    padding-left: 50px;
  }
  @media screen and (max-width: 575px) {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 40px;
  }
  h4 {
    margin-bottom: 20px;
  }
  .anticon {
    width: 50px;
    height: 50px;
    border-radius: 100px;
    svg {
      font-size: 1.5rem;
    }
  }
}
