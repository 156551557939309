.login-wrap {
  display: flex;
  min-height: 100vh;
  .login-form-wrap {
    display: flex;
    width: calc(100% - 50%);
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-right: 0;
    padding: 15px;
    padding-top: 40px;
    box-sizing: border-box;
    .ant-form-item {
      margin-bottom: 14px;
    }
    .left-arrow {
      cursor: pointer;
      position: absolute;
      left: 15px;
      top: 15px;
      width: 24px;
      height: 24px;
    }
    h2 {
      margin: 0;
      color: #d9d9d9;
      text-align: left !important;
      width: 100%;
      margin-bottom: 20px;
    }
    .checkbox {
      .ant-row {
        flex-direction: row-reverse;
        align-items: center;
        gap: 2px;
        justify-content: flex-end;
        padding-left: 10px;
      }
      .ant-col {
        width: auto !important;
        flex: initial;
        padding-bottom: 0;
      }
    }
    .ant-checkbox-inner {
      background-color: transparent;
    }
  }
}

.login-thum-wrap {
  width: 50%;
  height: 100vh;
  position: fixed;
  right: 0;
  &:after {
    content: "";
    display: block;
    padding-top: 100%;
  }
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.form-wrap {
  max-width: 400px;
  width: 100%;
  label {
    flex-direction: row-reverse;
    color: #d9d9d9 !important;
  }
  .text-field {
    padding: 10px 20px;
  }
  .text-field,
  .ant-input-affix-wrapper {
    color: #d9d9d9;
    border: solid 2px #d9d9d9;
    background-color: transparent;
    border-radius: 50px !important;
    padding: 10px 20px;
    svg {
      color: #d9d9d9;
    }
  }
  .ant-input-affix-wrapper input {
    background-color: transparent;
    color: #d9d9d9;
  }
  button {
    height: 48px !important;
    border-radius: 50px !important;
    border: solid 1px #ffb100;
    &:hover {
      color: #ffb100 !important;
      background-color: transparent !important;
    }
  }
}

.google-btn {
  margin-bottom: 15px;
  color: #d9d9d9 !important;
  border: solid 2px #d9d9d9 !important;
  .ant-image {
    margin-right: 5px;
  }
}
