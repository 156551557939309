.single-game-master {
  padding-top: 140px;
  .content-inr {
    width: 100% !important;
    flex: 0 0 100% !important;
  }
}
.gm-cover-wrap {
  position: relative;
  &::after {
    content: "";
    display: block;
    padding-top: 300px;
  }
  .single-gaming-cover {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: cover;
    border-top-left-radius: 40px;
    border-bottom-right-radius: 40px;
  }
  .ant-image {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: -50px;
    width: 140px;
    height: 140px;
    border-radius: 100px;
    overflow: hidden;
    border: solid 5px #0e1020;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.gm-content {
  padding-top: 100px;
  .text {
    margin: 0;
    font-size: 1rem;
  }
  .content-inr {
    h2.ant-typography {
      margin: 0;
      color: #ffffff !important;
      span {
        font-size: 1rem;
        color: #b1b1c8 !important;
      }
    }
  }
  max-width: 100%;
  .game-master-list {
    display: flex;
    list-style: none;
    padding-left: 0;
    margin: 30px 0 30px;
    gap: 15px;
    flex-wrap: wrap;
    li {
      font-size: 1rem;
      margin: 0;
      width: calc(25% - 11.5px);
      padding: 25px;
      background-color: #171734;
      border-radius: 6px;
      display: flex;
      align-items: center;
      gap: 20px;
      &.gm-experience {
        .ant-image img {
          height: 14px !important;
        }
      }
      &.discord-tag {
        .ant-image img {
          height: 18px !important;
        }
      }
      .ant-image {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        background-color: rgba(115, 103, 240, 0.12) !important;
        img {
          width: 24px;
          height: 24px !important;
        }
      }
    }
  }
}

.gm-games {
  .upcoming-cards {
    max-width: 100%;
    margin: 0;
  }
}
