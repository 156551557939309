.create-game {
  padding: 50px 20px;
}

.upload-game-profile {
  .ant-upload {
    width: 100%;
  }
  .ant-upload-list-item {
    background-color: #171734 !important;
    border-color: rgba(121, 121, 121, 0.2588235294) !important;
    &-name {
      color: #b1b1c8;
    }
    .ant-btn-icon svg {
      fill: red;
    }
  }
  button.ant-btn-background-ghost {
    color: #b1b1c8;
    border-color: rgba(121, 121, 121, 0.2588235294);
  }
}

.age-restriction-check {
  .ant-checkbox-inner {
    background-color: transparent;
  }
}
